import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useNotify } from "../../common/useNotify";
import { useEffect, useState } from "react";
import { initFlowbite } from "flowbite";
import { Get } from "../../common/request";
import { BaseUrl, LoadingView } from "../../common/global";
import { PaginationView } from "../../common/pagination";

export const UserOrder = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  const { page = 0 } = useParams();

  useEffect(() => { initFlowbite(); }, []);

  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useQuery(['GetOrderList', page], () => Get({
    url: "/Member/Order/GetOrderList", jsonData: { page }
  }), { refetchOnWindowFocus: false });

  if (isLoading)
    return <LoadingView />
  if (isError) {
    return <div>{`${error}`}</div>
  }
  if (!isSuccess || data == undefined || data == null) {
    return <div>{`未知错误!`}</div>
  }

  const { items, pageIndex, pageSize, totalPage, total } = data as any;
  const goPage = (pageIndex: number, _status: number) => {
    navigate(`/user/orders/${pageIndex}`);
  }
  return (
    <div className="flex flex-col pt-0 items-center h-full space-y-3 pb-40 md:pb-3">
      <div className="px-3 w-full">
        <div className="text-gray-900 dark:text-white text-base font-bold my-5">
          <span className="border-b-2 border-blue-500 pb-1">订单列表</span>
        </div>
        <table className=" hidden md:block text-sm w-full shadow text-left rtl:text-right text-gray-900 dark:text-gray-400">
          <thead className=" text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className=" p-3">
                商品信息
              </th>
              <th scope="col" className=" p-3  text-center">
                运费
              </th>
              <th scope="col" className=" p-3  text-center">
                应付
              </th>
              <th scope="col" className=" p-3  text-center">
                优惠
              </th>
              <th scope="col" className=" p-3  text-center">
                实付
              </th>
              <th scope="col" className=" p-3  text-center">
                已付
              </th>
              <th scope="col" className=" p-3  text-center">
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ orderNum, createOrderTime, payTime, status, totalPrice, weifuPrice, yifuPrice, youhuiPrice, yunFei, _pros, _can: { detail, pay, cancel } }: any) =>
              <tr className="align-top odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="w-72 text-sm p-3 font-medium whitespace-nowrap dark:text-white">
                  <div className="flex flex-col space-y-2">
                    <div className="flex justify-between text-gray-900">
                      <div className="pr-3">{status}</div>
                      <div className="pr-3">订单#：<span className=" text-blue-600">{orderNum}</span></div>
                      {payTime ? <div>支付时间：{payTime}</div> : <div>下单时间：{createOrderTime}</div>}
                    </div>
                    {_pros.map(({ pid, title, tag1, tag2, price, pic, count }: any) =>
                      <div className="flex">
                        <div><img className=" border w-14 h-14" src={`${BaseUrl}${pic}`} /></div>
                        <div className="px-3 text-sm">
                          <div className="text-gray-400 w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap">{title}</div>
                          <div className="text-gray-300 w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap">{tag1}</div>
                          <div className="text-gray-300 w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap">{tag2}</div>
                        </div>
                        <div className="flex-1"></div>
                        <div className="text-sm text-gray-400">￥{price.toLocaleString('en-US')} x {count}</div>
                      </div>
                    )}
                  </div>
                </th>
                <td className="text-sm p-3 align-top text-center">
                  ￥{yunFei.toLocaleString('en-US')}
                </td>
                <td className="text-sm p-3 align-top text-center">
                  ￥{(totalPrice + youhuiPrice).toLocaleString('en-US')}
                </td>
                <td className="text-sm p-3 align-top text-center">
                  ￥{youhuiPrice.toLocaleString('en-US')}
                </td>
                <td className="text-sm p-3 align-top text-center">
                  ￥{(weifuPrice + yifuPrice).toLocaleString('en-US')}
                </td>
                <td className="text-sm p-3 align-top text-center">
                  <span className="text-orange-500">￥{yifuPrice.toLocaleString('en-US')}</span>
                </td>
                <td className="p-3 align-top w-36">
                  <div className="flex flex-col items-center space-y-2">
                    {detail && <a href="#" className=" inline-flex justify-center rounded-lg  border border-gray-200 bg-white px-3 py-1 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 lg:w-auto">订单详情</a>
                    }
                    {cancel && <button type="button" className="rounded-lg border border-red-700 px-3 py-1 text-center text-sm font-medium text-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-600 dark:hover:text-white dark:focus:ring-red-900 lg:w-auto">取消订单</button>
                    }
                    {pay && <button type="button" className="rounded-lg bg-primary-700 px-3 py-2 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 lg:w-auto">支付订单</button>
                    }
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="md:hidden text-sm w-full text-left rtl:text-right text-gray-900 dark:text-gray-400">
          {items.map(({ orderNum, createOrderTime, payTime, status, totalPrice, weifuPrice, yifuPrice, youhuiPrice, yunFei, _pros, _can: { detail, pay, cancel } }: any) =>
            <div className="align-top  bg-white border-b dark:border-gray-700 mb-5">
              <div className="text-sm p-3">
                <div className="flex justify-between">
                  <div className="">订单编号：<span className="">{orderNum}</span></div>
                  <div className="">{status}</div>
                </div>
                <div>下单时间：{createOrderTime}</div>
                {payTime && <div>支付时间：{payTime}</div>}
                <div className="p-3 flex flex-col space-y-1">
                  {_pros.map(({ pid, title, tag1, tag2, price, pic, count }: any) =>
                    <div className="flex">
                      <div><img className=" border w-14 h-14" src={`${BaseUrl}${pic}`} /></div>
                      <div className="px-3 text-sm">
                        <div className="text-gray-400 w-[260px] overflow-ellipsis overflow-hidden whitespace-nowrap">{title}</div>
                        <div className="text-xs text-gray-300 w-[260px] overflow-ellipsis overflow-hidden whitespace-nowrap">{tag1}</div>
                        <div className="text-xs text-gray-300 w-[260px] overflow-ellipsis overflow-hidden whitespace-nowrap">{tag2}</div>
                      </div>
                      <div className="flex-1"></div>
                      <div className="text-sm text-gray-400">￥{price.toLocaleString('en-US')} x {count}</div>
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <div className="">运费：￥{yunFei.toLocaleString('en-US')}</div>
                  <div className="">应付：￥{(totalPrice + youhuiPrice).toLocaleString('en-US')}</div>
                </div>
                <div className="flex justify-between">
                  <div className="">优惠：￥{youhuiPrice.toLocaleString('en-US')}</div>
                  <div className="">实付：￥{(weifuPrice + yifuPrice).toLocaleString('en-US')}</div>
                </div>
                <div className="flex justify-between">
                  <div className="">已付：<span className="text-orange-500">￥{yifuPrice.toLocaleString('en-US')}</span></div>
                </div>
                <div>
                  <div className="flex space-x-2 justify-end">
                    {detail && <a href="#" className=" inline-flex justify-center rounded-lg  border border-gray-200 bg-white px-3 py-1 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 lg:w-auto">订单详情</a>
                    }
                    {cancel && <button type="button" className="rounded-lg border border-red-700 px-3 py-1 text-center text-sm font-medium text-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-600 dark:hover:text-white dark:focus:ring-red-900 lg:w-auto">取消订单</button>
                    }
                    {pay && <button type="button" className="rounded-lg bg-primary-700 px-3 py-2 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 lg:w-auto">支付订单</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-center">
          <PaginationView pageIndex={pageIndex} totalPage={totalPage} goPage={goPage} />
        </div>
      </div>
    </div>
  )
};
