import { initFlowbite } from "flowbite";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom"
import { clearToken, decodeToken, DengView, header_pt, isLogin, LoadingView, WapFooterTxt } from "../common/global";
import { useSearchContext } from "../common/useSearchContext";
import { useQuery } from "react-query";
import { Get, PostData } from "../common/request";
import Marquee from "react-easy-marquee";
import { useShoppingCarCount } from "../common/useShoppingCarCount";

const PCSearch = () => {
    const navigate = useNavigate();
    const inputRef = useRef<any>(null);
    const dropdownDomRef = useRef<any>(null);

    const { key = '', page = 0 } = useParams();
    const { dropdown, focusHandler, inputHandler, keyDownHandler, searchVal, setSearchVal, selectIndex, setSelectIndex, tipDatas } = useSearchContext(inputRef, dropdownDomRef);

    useEffect(() => {
        setSearchVal(key);
    }, []);

    const searchTipClick = (tipTitle: string) => {
        setSearchVal(tipTitle);
        dropdown.hide();

        // setSearchHistory(tipTitle)
        //执行搜索
        // navigate('/search/' + encodeURIComponent(tipTitle));
        navigate('/search/' + tipTitle);
    }
    const submit = (e: any) => {
        searchTipClick(searchVal);
        e.preventDefault(); //阻止提交
    }

    return (
        <form id='s1' onSubmit={submit}>
            <input type="search"
                ref={inputRef}
                onInput={inputHandler}
                onFocus={focusHandler}
                onKeyDown={keyDownHandler}
                value={searchVal}
                className="block w-full p-3 ps-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="产品、品牌、别名、疾病名称" />
            <button type="submit" className="text-white absolute end-2 bottom-[7px] bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-yellow-600 dark:hover:bg-blue-700 dark:focus:ring-yellow-800">搜索</button>

            <div
                ref={dropdownDomRef}
                className="z-30 hidden border border-gray-300 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700">
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
                    {tipDatas.map((tipTitle: string, _ind: number) => {
                        const choose = _ind == selectIndex;
                        return <li onClick={() => searchTipClick(tipTitle)}>
                            <a className={choose ? "block px-4 py-2 bg-gray-200 dark:bg-gray-600 dark:text-white" : "block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"}>{tipTitle}</a>
                        </li>
                    }
                    )}
                </ul>
            </div>
        </form>
    )
}

export const PCMenus = ({ data }: any) => {
    const navigate = useNavigate();
    return (
        <ul className=" text-sm text-white dark:text-gray-200" >
            {data.filter((t: any) => t.Type == 'sort').map(({ id, Type, Name, Img }: any) =>
                <li onClick={() => navigate(`/list/${Type}/${id}`)} className=" cursor-pointer flex px-4 items-center space-x-3 py-[8.5px] hover:bg-[#00396B] border-t-[1px] border-[#3674ac]">
                    <img className="w-6 h-6" src={Img} />
                    <span>{Name}</span>
                </li>
            )}
        </ul>
    )
}

const PCHeaderMenu = () => {
    const navigate = useNavigate();
    const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['WapData_SortDTOs'], () => PostData({
        url: "/Home/WapData", jsonData: {}
    }));
    if (isLoading)
        return null;
    if (isError) {
        return <div>{`${error}`}</div>
    }
    if (!isSuccess || data == undefined || data == null) {
        return <div>{`未知错误!`}</div>
    }
    // console.log('headerdata', data)
    return (
        <div className="bg-[#00396A] h-10 hidden md:block mt-[25px]">
            <div className="container mx-auto max-w-screen-xl">
                <div className="hidden md:flex">
                    <ul className="flex text-white">
                        <li data-dropdown-toggle="dropdown1" data-dropdown-offset-distance="0" className=' cursor-pointer h-10 flex items-center justify-between px-5 bg-[#3388CD] hover:text-[#ffbb00] leading-none w-48'><span>全部分类</span>
                            <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </li>
                        <li onClick={() => navigate(`/`)} className=' cursor-pointer h-10 flex items-center px-7 hover:bg-[#004A7B] hover:text-[#ffbb00] leading-none'><span>首页</span></li>
                        {data.filter((t: any) => t.Type == 'supply').map(({ id, Type, Name, Img }: any) =>
                            <li onClick={() => { navigate(`/list/${Type}/${id}`) }} className=' cursor-pointer h-10 flex items-center px-7 hover:bg-[#004A7B] hover:text-[#ffbb00] leading-none'><span>{Name}</span></li>
                        )}
                    </ul>

                    <div id="dropdown1" className="z-10 hidden w-48 bg-[#3388CD]"  >
                        <PCMenus data={data} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export const HeaderAndFooterView = () => {
    const navigate = useNavigate();
    let location = useLocation();

    // console.log('location.key', location.key, location.pathname)
    //判断是否有上一页
    const doesAnyHistoryEntryExist = location.key !== "default" && location.pathname != '/';

    const [headerTitle, _setHeaderTitle] = useState('');
    const setHeaderTitle = (title: string) => {
        _setHeaderTitle(title);
        document.title = title.indexOf("细柔") < 0 ? `${title} - 细柔` : title;
    }
    // const [openSearch, setOpenSearch] = useState(false);

    const { id, role, Account, NickName, Avatar }: any = decodeToken();

    useEffect(() => {
        initFlowbite();
        // function seachOutsideClick(event: any) {
        //     setOpenSearch(false)
        // }
        // document.body.addEventListener("click", seachOutsideClick);
        // return () => {
        //     document.body.removeEventListener("click", seachOutsideClick);
        // }
    }, []);

    const logout = () => {//退出
        clearToken();
        navigate('/login')
    }

    const inFwsPages = () => {//服务商页面
        const { pathname } = location;
        return pathname.toLowerCase().indexOf('/fws') == 0;
    }
    const inUserPages = () => {//会员页面
        const { pathname } = location;
        return pathname.toLowerCase().indexOf('/user') == 0;
    }

    const inHomePage = () => {//首页
        const { pathname } = location;
        return pathname.toLowerCase() === '/';
    }
    const inSearchPage = () => {//搜索页面
        const { pathname } = location;
        return pathname.toLowerCase().indexOf('/search') == 0;
    }
    const inShoppingCarPage = () => {//购物车页面
        const { pathname } = location;
        return pathname.toLowerCase().indexOf('/shoppingCar'.toLowerCase()) == 0;
        // return false;
    }
    const inOrderPage = () => {//我的订单页面
        return false;
    }

    const isFws = () => {//服务商角色
        const { role } = decodeToken();
        // console.log('role', role, role.indexOf('FWS') >= 0)
        return role.indexOf('FWS') >= 0;
    }
    const goUserPage = () => {//进入服务商或会员中心首页
        if (!isLogin()) { navigate('/login'); return; }
        if (isFws()) { navigate('/fws'); return; }
        navigate('/user');
    }

    const getHello = () => {
        let Hello = '您好';
        const hours = new Date().getHours();
        if (hours >= 0 && hours < 6) { Hello = "凌晨好"; }
        if (hours >= 6 && hours < 9) { Hello = "早上好"; }
        if (hours >= 9 && hours < 11) { Hello = "上午好"; }
        if (hours >= 11 && hours < 13) { Hello = "中午好"; }
        if (hours >= 13 && hours < 17) { Hello = "下午好"; }
        if (hours >= 17 && hours < 19) { Hello = "傍晚好"; }
        if (hours >= 19 && hours < 24) { Hello = "晚上好"; }
        return Hello;
    }

    return (
        <div className="antialiased bg-gray-50 dark:bg-gray-900">
            <nav className="bg-[#006090] py-2.5 fixed md:relative left-0 right-0 top-0 z-50 md:h-52 ">
                <div className="container mx-auto hidden md:block max-w-screen-xl">
                    <div className="flex space-x-5 text-white text-sm items-center py-2">
                        <span>{getHello()}</span>
                        <div className=" flex-1"></div>
                        <DengView align={'end'} width='350px' />
                        <a href="/help/24" className="flex items-center">
                            <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <span>帮助</span>
                        </a>
                    </div>
                    <hr className="h-px mb-6 bg-gray-400 opacity-45 border-0 dark:bg-gray-700" />
                </div>
                <div className="container mx-auto max-w-screen-xl">
                    <div className="flex flex-wrap justify-between items-center">
                        <div className="flex-1 w-1 md:flex-none  md:w-auto flex justify-start items-center">
                            {/* 会员中心或服务商中心 */}
                            {isLogin() && (inFwsPages() || inUserPages()) &&
                                <button
                                    data-drawer-target="drawer-navigation"
                                    data-drawer-toggle="drawer-navigation"
                                    aria-controls="drawer-navigation"
                                    className="p-2 mx-2 text-white rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <svg
                                        aria-hidden="true"
                                        className="hidden w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            }

                            {/* Wap */}
                            {
                                !inFwsPages() && !inUserPages() && doesAnyHistoryEntryExist &&
                                <button className="md:hidden p-2 mx-2" onClick={() => navigate(-1)}>
                                    <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7" />
                                    </svg>
                                </button>
                            }
                            <div className="flex-1 w-1 flex md:hidden items-center justify-between mx-2">
                                <a onClick={() => navigate("/")}>
                                    <img src="/xirou.png" className="mr-3 h-8 rounded-full" />
                                </a>
                                <span className="flex-1 text-lg text-white dark:text-white overflow-ellipsis overflow-hidden whitespace-nowrap">{headerTitle ?? '细柔'}</span>
                            </div>

                            {/* PC */}
                            <a onClick={() => navigate('/')} className="hidden md:flex items-center justify-between mr-4">
                                <img src="https://www.xirou.com/UploadFolder/2018-05-31/2018053110542280202164.png" />
                            </a>
                        </div>
                        <div className="hidden md:block flex-1 pl-0 lg:pl-6 pr-6 lg:pr-16 max-w-2xl">
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <PCSearch />
                            </div>
                        </div>
                        <div className="flex items-center lg:order-2 h-10">
                            {!inSearchPage() &&
                                <button type="button" onClick={(e) => {
                                    navigate('/search');
                                    //setOpenSearch(!openSearch); e.nativeEvent.stopImmediatePropagation(); 
                                }} className="p-2 mr-1 text-white rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600">
                                    <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path clip-rule="evenodd" fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path>
                                    </svg>
                                </button>
                            }
                            {/* {openSearch &&
                                <div onClick={(e) => { e.nativeEvent.stopImmediatePropagation(); }} className="md:hidden px-2 bg-[#006090] absolute left-0 right-0 -bottom-16 h-16">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-5 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="search" className="block w-full p-3 ps-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-1.5" placeholder="搜索产品名称、品牌、其它别名、疾病名称" />
                                    <button type="submit" className="text-white absolute end-4 bottom-[15px] bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-yellow-600 dark:hover:bg-blue-700 dark:focus:ring-yellow-800">搜索</button>
                                </div>
                            } */}
                            {!isLogin() ?
                                <>
                                    <a onClick={() => navigate('/reg')} className="text-white  hover:bg-yellow-500  dark:text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5">注册</a>
                                    <a onClick={() => navigate('/login')} className="text-white  hover:bg-yellow-500  dark:text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2">登陆</a>
                                </>
                                :
                                <>
                                    <ShoppingCarView />
                                    <button
                                        type="button"
                                        className="flex bottom-0 mx-3 text-sm rounded-full md:mr-0 focus:ring-gray-300 dark:focus:ring-gray-600"

                                        aria-expanded="false"
                                        data-dropdown-toggle="dropdownUser"
                                    >
                                        <img className="w-8 h-8 rounded-full" src={Avatar} />
                                    </button>
                                    <div className="hidden z-50 my-4 w-56 text-base list-none bg-white  divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl" id="dropdownUser"
                                    >
                                        <div className="py-3 px-4">
                                            <span className="block text-lg text-gray-900 dark:text-white">{NickName}</span>
                                            <span className="block text-sm text-gray-900 truncate dark:text-white" >{Account}</span>
                                        </div>
                                        <ul className="py-1 text-gray-700 dark:text-gray-300"  >
                                            <li>
                                                <a href='/user' className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">会员中心</a>
                                            </li>
                                            {isFws() && <li>
                                                <a href='/fws' className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">服务商中心</a>
                                            </li>
                                            }
                                            <li>
                                                <a
                                                    href="#"
                                                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                                                >设置中心</a
                                                >
                                            </li>
                                        </ul>
                                        <ul
                                            className="py-1 text-gray-700 dark:text-gray-300"

                                        >
                                            <li>
                                                <a
                                                    href="#"
                                                    className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                ><svg
                                                    className="mr-2 w-5 h-5 text-gray-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                    我的收藏</a
                                                >
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                ><svg
                                                    className="mr-2 w-5 h-5 text-gray-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                        <path
                                                            d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                                                        ></path>
                                                    </svg>
                                                    我的订单</a
                                                >
                                            </li>
                                        </ul>
                                        <ul
                                            className="py-1 text-gray-700 dark:text-gray-300"

                                        >
                                            <li onClick={logout}>
                                                <a
                                                    className=" cursor-pointer block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >退出</a>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <PCHeaderMenu />
            </nav>

            <div className={header_pt}>
                <Outlet context={[setHeaderTitle]} />
            </div>

            <div className="md:hidden fixed z-30 w-full  max-w-lg -translate-x-1/2 bottom-4 left-1/2 ">
                <div className="mx-2  rounded-full  border border-gray-200 h-16 bg-white  dark:bg-gray-700 dark:border-gray-600">
                    <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                        <button aria-checked={inHomePage()} onClick={() => navigate('/')} type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group aria-checked:bg-gray-50 dark:aria-checked:bg-gray-800">
                            <svg className="w-6 h-6 text-gray-400 dark:text-gray-400 group-hover:text-primary-800 dark:group-hover:text-primary-800 group-aria-checked:text-primary-800 dark:group-aria-checked:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />
                            </svg>
                        </button>
                        {/* <div id="tooltip-home" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Home
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div> */}

                        <button aria-checked={inSearchPage()} onClick={() => navigate('/search')} type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group aria-checked:bg-gray-50 dark:aria-checked:bg-gray-800">
                            <svg className="w-6 h-6 text-gray-400 dark:text-gray-400 group-hover:text-primary-800 dark:group-hover:text-primary-800 group-aria-checked:text-primary-800 dark:group-aria-checked:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-width="2.4" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                            </svg>
                        </button>
                        {/* <div id="tooltip-category" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Category
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div> */}

                        <div aria-checked={inShoppingCarPage()} className="flex items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-800 group aria-checked:bg-gray-50 dark:aria-checked:bg-gray-800">
                            <ShoppingCarView_Wap />
                        </div>
                        {/* <div id="tooltip-shoppingCar" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Shopping Car
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div> */}

                        <button aria-checked={inOrderPage()} onClick={() => { if (isFws()) { navigate('/fws/orders') } else { navigate('/user/orders') } }} type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group aria-checked:bg-gray-50 dark:aria-checked:bg-gray-800">
                            <svg className="w-6 h-6 text-gray-400 dark:text-gray-400 group-hover:text-primary-800 dark:group-hover:text-primary-800 group-aria-checked:text-primary-800 dark:group-aria-checked:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" d="M10 3v4a1 1 0 0 1-1 1H5m4 8h6m-6-4h6m4-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z" />
                            </svg>
                        </button>
                        {/* <div id="tooltip-orders" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Orders
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div> */}

                        <button aria-checked={inFwsPages() || inUserPages()} onClick={() => goUserPage()} type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-gray-50 dark:hover:bg-gray-800 group aria-checked:bg-gray-50 dark:aria-checked:bg-gray-800">
                            {isLogin() ?
                                <img className="w-7 h-7 rounded-full grayscale contrast-50 group-aria-checked:grayscale-0 group-aria-checked:contrast-100" src={Avatar} />
                                :
                                <svg className="w-6 h-6 text-gray-400 dark:text-gray-400 group-hover:text-primary-800 dark:group-hover:text-primary-800 group-aria-checked:text-primary-800 dark:group-aria-checked:text-primary-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                            }
                        </button>
                        {/* <div id="tooltip-me" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Me
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div> */}
                    </div>
                </div>
            </div>

            <FooterView />

        </div>
    )
}

const ShoppingCarView = () => {
    const navigate = useNavigate();
    const { shoppingCarCount, refetchShoppingCarCount } = useShoppingCarCount();
    useEffect(() => {
        refetchShoppingCarCount();
    }, []);
    return (
        <button onClick={() => navigate('/shoppingCar')} type="button" className="hidden md:inline-flex text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-[#F7BE38]/50 font-medium rounded-full text-sm px-5 py-1.5 text-center  items-center dark:focus:ring-[#F7BE38]/50 me-3">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312" />
            </svg>
            {`购物车${shoppingCarCount >= 0 ? `(${shoppingCarCount})` : ''}`}
        </button>
    )
}
const ShoppingCarView_Wap = () => {
    const navigate = useNavigate();
    const { shoppingCarCount, refetchShoppingCarCount } = useShoppingCarCount();
    useEffect(() => {
        refetchShoppingCarCount();
    }, []);
    return (
        <button onClick={() => navigate('/shoppingCar')} type="button" className="inline-flex items-center justify-center w-10 h-10 font-medium  rounded-full bg-gray-400 dark:bg-gray-400 group-hover:bg-primary-800 group-aria-checked:bg-primary-800 relative ">
            <svg className="w-6 h-6 text-white dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312" />
            </svg>
            {shoppingCarCount > 0 &&
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{shoppingCarCount}</div>
            }
        </button>
    )
}

const FooterView = () => {
    const navigate = useNavigate();

    const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['PCFooterData'], () => PostData({
        url: "/Home/PCFooterData", jsonData: {}
    }));
    if (isLoading)
        return null
    if (isError) {
        return <div>{`${error}`}</div>
    }
    if (!isSuccess || data == undefined || data == null) {
        return <div>{`未知错误!`}</div>
    }
    // console.log('data', data)

    return (
        <footer className="hidden md:block bg-[#006090]  antialiased dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                <div className="border-b border-gray-400 py-6 dark:border-gray-700 md:py-8 lg:py-16">
                    <div className="items-start gap-6 md:gap-8 lg:flex 2xl:gap-24">
                        <div className="grid min-w-0 flex-1 grid-cols-2 gap-6 md:gap-8 xl:grid-cols-3">
                            {data.map(({ id, Typename, List }: any) =>
                                <div>
                                    <h6 className="mb-4 text-sm  uppercase text-gray-400 dark:text-white">{Typename}</h6>
                                    <ul className="space-y-3">
                                        {List.map(({ id: msgId, Ftitle: msgTitle }: any) =>
                                            <li>
                                                <a href={"/help/" + msgId} title="" className="text-gray-200 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"> {msgTitle}</a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="mt-6 w-full md:mt-8 lg:mt-0 lg:max-w-lg">
                            <div className="space-y-5 rounded-lg bg-gray-50 p-6 dark:bg-gray-700">
                                <a href="/login" title="" className="text-base font-medium text-primary-700  dark:text-primary-500">登陆或注册</a>

                                <hr className="border-gray-200 dark:border-gray-600" />

                                <form action="#">
                                    <div className="items-end space-y-4 sm:flex sm:space-y-0">
                                        <div className="relative mr-3 w-full sm:w-96 lg:w-full">
                                            <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300">订阅获取消息</label>
                                            <input className="block w-full rounded-lg border border-gray-300 bg-white p-3 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-200 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:w-96 lg:w-full" placeholder="输入您的邮箱" type="email" />
                                        </div>
                                        <div>
                                            <button onClick={() => alert('暂未开放订阅')} type="button" className="w-full cursor-pointer rounded-lg bg-primary-700 px-5 py-3 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 break-keep">订阅</button>
                                        </div>
                                    </div>
                                </form>

                                <hr className="border-gray-200 dark:border-gray-600" />

                                <div>
                                    {/* <p className="mb-3 text-sm font-medium text-gray-900 dark:text-white">Trade on the go with <a href="#" title="" className="underline hover:no-underline">Flowbite App</a></p> */}

                                    <div className="gap-4 space-y-4 sm:flex sm:space-y-0">
                                        {/* <a href="#" className="inline-flex w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2.5 text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-200 dark:focus:ring-gray-800 sm:w-auto">
                                            <svg className="mr-3 h-7 w-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path>
                                            </svg>
                                            <div className="text-left">
                                                <div className="mb-1 text-xs">Download on the</div>
                                                <div className="-mt-1 font-sans text-sm ">Google Play</div>
                                            </div>
                                        </a>

                                        <a href="#" className="inline-flex w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2.5 text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-200 dark:focus:ring-gray-800 sm:w-auto">
                                            <svg className="mr-3 h-7 w-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                                ></path>
                                            </svg>
                                            <div className="text-left">
                                                <div className="mb-1 text-xs">Download on the</div>
                                                <div className="-mt-1 font-sans text-sm ">Mac App Store</div>
                                            </div>
                                        </a> */}

                                    </div>
                                </div>

                                {/* <hr className="border-gray-200 dark:border-gray-600" />

                                <div className="flex space-x-4">
                                    <a href="#" className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                                        </svg>
                                    </a>

                                    <a href="#" className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="flex pt-8 space-x-6 text-sm text-gray-400 [&_img]:h-8 justify-center">
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/zhongguo.jpg" />
                        <span>China</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/meiguo.jpg" />
                        <span>USA</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/deguo.jpg" />
                        <span>Germany</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/riben.jpg" />
                        <span>Japan</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/ruishi.jpg" />
                        <span>Switzerland</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/jianada.jpg" />
                        <span>Canada</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/yinguo.jpg" />
                        <span>England</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/tuerqi.jpg" />
                        <span>Turkey</span></li>
                    <li className="flex flex-col items-center">
                        <img src="https://www.xirou.com/App_Themes/UI/guoqi/yindu.jpg" />
                        <span>India</span></li>
                </ul>

                <div className="py-6 md:py-8">
                    <div className="gap-4 space-y-5 xl:flex xl:items-center xl:space-y-0">
                        <a onClick={() => navigate("/")} title="" className="block">
                            <img className="h-8 w-auto " src="/xirou.png" />
                        </a>

                        <ul className="flex flex-wrap items-center gap-4 text-sm text-gray-300 dark:text-white xl:justify-center">
                            <li><a href="https://xirou.org" target="_blank" className="font-medium hover:underline">全球医生</a></li>
                            <li><a href="https://xirou.net" target="_blank" className="font-medium hover:underline">国际体检</a></li>
                            <li><a href="https://xirou.ca" target="_blank" className="font-medium hover:underline">世界医学</a></li>
                        </ul>
                        <div className=" hidden xl:block flex-1"></div>

                        <p className="text-sm text-gray-200 dark:text-gray-400">Copyright 2015-现在 @ Xirou.com All Rights Reserved 版权所有：细柔健康 <a href="mailto:info@xirou.net" className="hover:underline">info@xirou.net</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}