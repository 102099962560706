import { initFlowbite } from 'flowbite';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { BaseUrl, isLogin, ProTags } from '../common/global';
import { useMutation, useQuery } from 'react-query';
import { IApiResult, PostData } from '../common/request';
import { useNotify } from '../common/useNotify';
import { useShoppingCarCount } from '../common/useShoppingCarCount';
import QRCode from 'qrcode';

export const Detail = () => {
  const { state } = useLocation()
  const { id } = useParams();
  const [setHeaderTitle] = useOutletContext() as any;
  const navigate = useNavigate();
  const notify = useNotify();
  const { refetchShoppingCarCount } = useShoppingCarCount();

  //使用 useState 和 useRef hooks 在事件监听器中访问 React 状态
  //https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [showTopBtn, setShowTopBtn] = useState(false);
  const showTopBtnRef = useRef(showTopBtn);
  const [showShareView, setShowShareView] = useState(false);

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['detail', id], () => PostData({
    url: "/Home/GetProductDetail", jsonData: { id }
  }), {});

  const { mutate: addShoppingCar, isLoading: addShoppingCar_isLoading } = useMutation({
    mutationFn: (data: any) => {
      return PostData({
        url: "/Member/ShoppingCar/AddShoppingCart", jsonData: { ...data, pid: id },
        fail: ({ Code, Message, Result }: IApiResult) => {
          notify.show({ message: Message, type: 'error' });
        }
      });
    },
    onSuccess: async ({ }: any) => {
      notify.show({ message: '加入购物车成功', type: 'success' });
      refetchShoppingCarCount();
      if (window.innerWidth < 768) {
        createBall();//特效
      }
    },
    onError: (message: string) => {
      // console.error(message);
    }
  });
  const _addShoppingCar = (data: any) => {
    if (!isLogin()) { navigate("/login") }
    else
      addShoppingCar(data);
  }

  const { mutate: addShoppingCarAndGo, isLoading: addShoppingCarAndGo_isLoading } = useMutation({
    mutationFn: (data: any) => {
      return PostData({
        url: "/Member/ShoppingCar/AddShoppingCart", jsonData: { ...data, pid: id },
        fail: ({ Code, Message, Result }: IApiResult) => {
          notify.show({ message: Message, type: 'error' });
        }
      });
    },
    onSuccess: async ({ }: any) => {
      refetchShoppingCarCount();
      navigate("/shoppingCar");
    },
    onError: (message: string) => {
      // console.error(message);
    }
  });
  const _addShoppingCarAndGo = (data: any) => {
    if (!isLogin()) { navigate("/login") }
    else
      addShoppingCarAndGo(data);
  }

  const { mutate: addShouCang, isLoading: addShouCang_isLoading } = useMutation({
    mutationFn: (data: any) => {
      return PostData({
        url: "/Home/AddShouCang", jsonData: data,
        fail: ({ Code, Message, Result }: IApiResult) => {
          notify.show({ message: Message, type: 'error' });
        }
      });
    },
    onSuccess: async ({ }: any) => {
      notify.show({ message: '操作成功', type: 'success' });
      refetch();
    },
    onError: (message: string) => {
      // console.error(message);
    }
  });

  useEffect(() => {
    initFlowbite();
    setHeaderTitle('Loading...');

    const scrollEvent = () => {
      const scrollTop = document.documentElement.scrollTop;
      const _showTopBtn = scrollTop > 1000;
      // console.log(_showTopBtn, showTopBtnRef.current)
      if (_showTopBtn != showTopBtnRef.current) {
        showTopBtnRef.current = _showTopBtn
        setShowTopBtn(_showTopBtn);
      }
    }
    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    }
  }, []);
  useEffect(() => {
    initFlowbite();
  }, [isLoading]);

  const LoadingView = () => {
    return (
      <div className="p-5 mt-2 max-w-sm animate-pulse">
        <div className='mb-10 flex items-center space-x-3'>
          <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <div className="text-sm">数据正在加载中，请稍后</div>
        </div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
      </div>
    )
  }

  if (isLoading) {
    if (state) {
      let { title, imgPath } = state as any;
      setHeaderTitle(title);
      return (
        <div className='container mx-auto  max-w-screen-xl flex flex-col pb-44'>
          <div className="relative w-full">
            <div className="relative h-56 overflow-hidden md:h-96">
              <img src={imgPath} className="object-cover absolute block h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
            </div>
          </div>
          <div className='text-[#003969] opacity-50 mx-auto font-bold mb-4'>图片与实物可能有出入，请以实物为准</div>
          <div className='w-full px-2'>
            <h5 className="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
          </div>
          <LoadingView />
        </div>
      )
    }
    else
      return <LoadingView />
  }
  if (isError) {
    return <div>{`${error}`}</div>
  }
  if (!isSuccess || data == undefined || data == null) {
    return <div>{`未知错误!`}</div>
  }

  const {
    product: { imgs, title, baozhuangTxt, gongsiTxt, jishuzhuangtai, P_Price, P_Price_sc, Price_UpdateTime, minCount, maxCount,
      detailRemarks, detailRemarksEN, shichangRemark, deliveryAddress,
      brandName, commonTitle, elseNames, company, country, proContent, proBaozhuang, geiyao, cuncu, storage, application,
      transportType, hzgm, hfgm, reservation, recommend },
    yunfei: { ProductYunFeiDetailList, SupplierCountry },
    psTime,
    _me: { IsProductCollection }
  } = data as any;

  setHeaderTitle(title);

  const createBall = () => {
    if (imgs.length < 1) return;
    const img = imgs[0];
    const innerHeight = window.innerHeight;
    console.log('innerHeight', innerHeight)
    const ball = document.createElement('div');
    ball.style.position = 'fixed';
    ball.style.zIndex = '49';
    ball.style.left = '0px';
    ball.style.bottom = (innerHeight - 350) + 'px';
    ball.style.right = '0px';
    ball.style.width = '70%';
    ball.style.opacity = '1';
    ball.style.margin = '0 auto';
    ball.style.borderRadius = '0px';
    ball.style.overflow = 'hidden';
    // ball.style.backgroundColor = 'red';
    // ball.innerHTML = `<img style='overflow:hidden;border-radius:1000px;vertical-align:middle' src='${'https://www.xirou.com/UploadFolder/2023-05-09/202305090631330148-490-460.jpg'}' />`;
    ball.innerHTML = `<img style='overflow:hidden;border-radius:1000px;vertical-align:middle' src='${BaseUrl + img}' />`;
    ball.style.transition = 'all 0.5s ease';
    document.body.appendChild(ball);
    // 使用 requestAnimationFrame 替代 setTimeout
    requestAnimationFrame(() => {
      ball.style.width = '36px';
      ball.style.borderRadius = '1000px';
      ball.style.bottom = '31px';
      ball.style.opacity = '0.3';
    });
    ball.ontransitionend = function () {
      ball.remove();
    };
  };

  const OrderBtnView = ({ min = 1, max = 99 }: any) => {
    const [val, setVal] = useState(1);
    const cut = () => {
      const _val = Math.max(min, val - 1);
      setVal(_val);
    }
    const add = () => {
      const _val = Math.min(max, val + 1);
      setVal(_val);
    }
    return (
      <>
        {
          !P_Price ?
            <FeiZCProBtnView hzgm={hzgm} hfgm={hfgm} reservation={reservation} />
            :
            <>
              <div className='hidden md:flex items-center space-x-2 mr-5'>
                <button type="button" className="group text-blue-700 border border-gray-300 hover:bg-gradient-to-r from-cyan-500 to-blue-500 hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500" onClick={cut}>
                  <svg className="w-4 h-4 group-hover:text-white text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14" />
                  </svg>
                </button>
                <input disabled type="tel" value={val} className="bg-gray-50 border text-center border-gray-300 text-gray-900 text-sm rounded-lg  block w-[40px] p-1.5   " />
                <button type="button" className="group text-blue-700 border border-gray-300  hover:bg-gradient-to-r from-cyan-500 to-blue-500 hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500" onClick={add}>
                  <svg className="w-4 h-4 group-hover:text-white text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                  </svg>
                </button>
              </div>

              <button onClick={() => addShoppingCar_isLoading ? null : _addShoppingCar({ count: val })} type="button" className={`w-32 text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-s-full text-sm px-5 py-3 text-center flex items-center ${addShoppingCar_isLoading ? 'opacity-65' : ''}`}>
                {addShoppingCar_isLoading ?
                  <svg className="w-4 h-4 mr-1 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  :
                  <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                  </svg>
                }
                <span className=''>{addShoppingCar_isLoading ? '等待中..' : '加入购物车'}</span>
              </button>

              <button onClick={() => addShoppingCarAndGo_isLoading ? null : _addShoppingCarAndGo({ count: val })} type="button" className={`w-32 px-5 py-3 text-sm font-medium text-white bg-gradient-to-br rounded-e-full from-pink-500 to-orange-400 hover:bg-gradient-to-bl  focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 text-center ${addShoppingCarAndGo_isLoading ? 'opacity-65' : ''}`}>
                <span className=''>{addShoppingCarAndGo_isLoading ? '等待中..' : '立即下单'}</span>
              </button>
            </>
        }
        <button type="button" onClick={() => setShowShareView(true)} className="hidden md:inline-flex text-gray-500 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 text-center items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 ms-2">
          <svg className="w-6 h-6 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
          </svg>
          分享
        </button>
      </>
    )
  }

  const shoucang = (status: number) => {
    if (!isLogin()) { navigate("/login") }
    else {
      addShouCang({ status, pid: id });
    }
  }

  return (
    <div className='container mx-auto  max-w-screen-xl flex flex-col pb-44'>
      <div className='flex flex-col lg:flex-row lg:py-8'>
        <div className='flex flex-col lg:flex-1'>
          <div className="relative w-full" data-carousel="slide">
            <div className="relative h-56 overflow-hidden md:h-96">
              {imgs.map((url: string) =>
                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                  <img src={BaseUrl + url} className="object-cover absolute block h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                </div>
              )}
            </div>

            {/* <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
              <button type="button" className="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
              <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
              <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
              <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
              <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 5" data-carousel-slide-to="4"></button>
            </div> */}

            <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
          <div className='text-[#003969] opacity-50 mx-auto font-bold mb-4'>图片与实物可能有出入，请以实物为准</div>
        </div>
        <div className='flex flex-col lg:flex-1'>
          <div className='w-full px-2'>
            <h5 className="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
            <div className='flex items-start'>
              <div className='flex-1'>
                <p className="text-sm text-gray-700 dark:text-gray-400">含量|包装：<span className='font-bold'>{baozhuangTxt}</span></p>
                <p className="text-sm text-gray-700 dark:text-gray-400">产地|公司：{gongsiTxt}</p>
                <p className="text-sm text-gray-700 dark:text-gray-400">技术|状态：{jishuzhuangtai}</p>
              </div>
              <div className='flex flex-col items-center'>
                {!IsProductCollection ?
                  <>
                    <svg onClick={() => shoucang(1)} className=" cursor-pointer w-9 h-9 text-gray-400 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-width="1" d="M11.083 5.104c.35-.8 1.485-.8 1.834 0l1.752 4.022a1 1 0 0 0 .84.597l4.463.342c.9.069 1.255 1.2.556 1.771l-3.33 2.723a1 1 0 0 0-.337 1.016l1.03 4.119c.214.858-.71 1.552-1.474 1.106l-3.913-2.281a1 1 0 0 0-1.008 0L7.583 20.8c-.764.446-1.688-.248-1.474-1.106l1.03-4.119A1 1 0 0 0 6.8 14.56l-3.33-2.723c-.698-.571-.342-1.702.557-1.771l4.462-.342a1 1 0 0 0 .84-.597l1.753-4.022Z" />
                    </svg>
                    <div className='text-gray-400 text-xs'>收藏</div>
                  </>
                  :
                  <>
                    <svg onClick={() => shoucang(0)} className=" cursor-pointer w-9 h-9 text-[#ff6600]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                    <div className='text-gray-400 text-xs'>已收藏</div>
                  </>
                }
              </div>
            </div>
          </div>
          <hr className="h-px mx-2 my-3 overflow-hidden bg-gray-200 border-0 dark:bg-gray-700" />
          <div className='w-full px-2'>
            <div className='flex items-baseline space-x-3'>
              {P_Price && <h5 className="mb-1 text-2xl font-bold tracking-tight text-[#ff6600]">￥{P_Price.toLocaleString('en-US')} </h5>}
              {P_Price_sc && <p className="text-sm text-gray-400 dark:text-gray-400 line-through">市场价：￥{P_Price_sc.toLocaleString('en-US')}</p>}
              <div className='flex-1'></div>
            </div>
            {Price_UpdateTime && <p className="text-xs text-gray-400 dark:text-gray-400">价格更新: {Price_UpdateTime}</p>}
            <ProTags className={P_Price ? 'text-xs mt-2' : 'mt-2 [&_span]:text-lg [&_span]:py-2 [&_span]:px-3'} transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
          </div>
          {/* <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" /> */}
          <div className='w-full px-2 mt-4'>
            <ul className='list-disc list-inside ml-2'>
              <li className="text-sm text-gray-700 font-bold"> {Math.max(1, minCount)}盒起售，限购{maxCount}盒</li>
              {/* <li className="text-sm text-gray-700">发货地：{SupplierCountry}</li> */}
              <li className="text-sm text-gray-700">图片与实物可能有出入，请以实物为准</li>
              <li className="text-sm text-gray-700">根据行业惯例，阁下所购本品属于最终决定</li>
              <li className="text-sm text-red-700">下单支付成功后不可退款退货</li>
            </ul>
          </div>
          <hr className="h-px mx-2 my-3 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className='w-full px-2 flex flex-col space-y-1'>
            <div className='flex space-x-3 items-baseline'>
              <h6 className="mb-1 text-base font-bold tracking-tight text-gray-700">运输运费</h6>
              <div className='flex space-x-3'>
                <p className="text-xs text-gray-700 dark:text-gray-400 pb-1">发货地：{SupplierCountry}</p>
                {/* <p className="text-xs text-gray-400 dark:text-gray-400 pb-1">{(psTime[2] > 0 && psTime[2] < 5) ? `预计${psTime[2]}天发出` : ''}</p>
                <p className="text-xs text-gray-400 dark:text-gray-400 pb-1">{(psTime[3] > 0 && psTime[3] < 20) ? `预计${psTime[3]}天收到（系统预测仅供参考）` : ''}</p> */}
                <p className="text-xs text-gray-400 dark:text-gray-400 pb-1">{psTime[2] > 0 ? `预计${psTime[2]}天发出` : ''}</p>
                <p className="text-xs text-gray-400 dark:text-gray-400 pb-1">{psTime[3] > 0 ? `预计${psTime[3]}天收到（系统预测仅供参考）` : ''}</p>
              </div>
            </div>
            {ProductYunFeiDetailList.map(({ C_Name, C_YFShowText, C_Remark }: any) =>
              <div className='border p-2 bg-gray-50'>
                <div className='text-[#ff6600] text-sm'>
                  【{C_Name}】{C_YFShowText}
                </div>
                <div className='text-xs text-gray-400 dark:text-gray-400'>
                  {C_Remark}
                </div>
              </div>
            )}
          </div>
          <div className='hidden md:flex justify-center lg:justify-start pt-5'>
            <OrderBtnView key={'pro' + id} min={Math.max(1, minCount)} max={maxCount ?? 10} />
          </div>
        </div>
      </div>
      <hr className="h-3 my-3 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className=' w-full px-2'>
        <div data-accordion="collapse">
          <h2>
            <button type="button" className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" >
              <span>产品参数</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" className="hidden">
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-600">
              <table className='border-collapse border border-dotted border-slate-300 w-full text-sm'>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>品牌名称</td>
                  <td className='border border-dotted border-slate-400 p-2'>{brandName}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>通用名称</td>
                  <td className='border border-dotted border-slate-400 p-2'>{commonTitle}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>关键信息</td>
                  <td className='border border-dotted border-slate-400 p-2'>{elseNames}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>公司</td>
                  <td className='border border-dotted border-slate-400 p-2'>{company}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>产地</td>
                  <td className='border border-dotted border-slate-400 p-2'>{country}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>含量</td>
                  <td className='border border-dotted border-slate-400 p-2'><span className='font-bold'>{proContent}</span></td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>包装</td>
                  <td className='border border-dotted border-slate-400 p-2'><span className='font-bold'>{proBaozhuang}</span></td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>剂型给药</td>
                  <td className='border border-dotted border-slate-400 p-2'>{geiyao}</td>
                </tr>
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>存储</td>
                  <td className='border border-dotted border-slate-400 p-2'>{cuncu}</td>
                </tr>
                {storage &&
                  <tr>
                    <td className='border border-dotted border-slate-400 p-2 min-w-24'>贮藏要求</td>
                    <td className='border border-dotted border-slate-400 p-2'>{storage}</td>
                  </tr>
                }
                <tr>
                  <td className='border border-dotted border-slate-400 p-2 min-w-24'>适用范围</td>
                  <td className='border border-dotted border-slate-400 p-2'>{application}</td>
                </tr>
              </table>
            </div>
          </div>

          <h2>
            <button type="button" className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-20 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false">
              <span>中文说明</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-2" className="hidden" >
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-600" dangerouslySetInnerHTML={{ __html: detailRemarks.replaceAll("/kindup/", BaseUrl + "/kindup/") || '暂无内容' }}>
            </div>
          </div>

          <h2>
            <button type="button" className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-20 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" >
              <span>外文说明</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-3" className="hidden">
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-600" dangerouslySetInnerHTML={{ __html: detailRemarksEN.replaceAll("/kindup/", BaseUrl + "/kindup/") || '暂无内容' }}>
            </div>
          </div>

          <h2>
            <button type="button" className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-20 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false">
              <span>市场资料</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-4" className="hidden">
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-600" dangerouslySetInnerHTML={{ __html: shichangRemark || '暂无内容' }}>
            </div>
          </div>

          <h2>
            <button type="button" className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-700 border border-gray-20 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false">
              <span>发货地点</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-5" className="hidden">
            <div className="p-5 border border-t-0 border-b-1 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-600">
              <img src={BaseUrl + deliveryAddress} />
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden z-30 fixed right-2 top-[70px]" >
        <button onClick={() => setShowShareView(true)} type="button" className="text-white bg-gray-400 hover:bg-gradient-to-bl font-medium rounded-full text-sm p-3 text-center">
          <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
          </svg>
        </button>
      </div>

      <div className="md:hidden fixed left-0 bottom-24 right-0 mx-auto flex justify-center" >
        <OrderBtnView />
      </div>

      {showTopBtn == true &&
        <div className=" fixed right-2 bottom-24" onClick={() => document.documentElement.scrollTo(0, 0)} >
          <button type="button" className="w-11 h-11 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full flex justify-center items-center">
            <svg className="w-8 h-8 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v13m0-13 4 4m-4-4-4 4" />
            </svg>
          </button>
        </div>
      }

      {showShareView && <ShareView logoUrl={'/xirou.png'} picUrl={BaseUrl + imgs[0]} id={id} title={title} price={`${P_Price ? `¥${P_Price.toLocaleString('en-US')}` : '预定&询价'}`} arg1={baozhuangTxt} arg2={gongsiTxt} arg3={jishuzhuangtai} setShowShareView={setShowShareView} />}
    </div>
  );
}

const fillText2 = (ctx: any, text: string, x: number, y: number, maxWidth: number, lineHeight: number) => {

  var lineCount = 1;
  // 字符分隔为数组
  var arrText = text.split('');
  var line = '';

  for (var n = 0; n < arrText.length; n++) {
    var testLine = line + arrText[n];
    var metrics = ctx.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      ctx.fillText(line, x, y);
      line = arrText[n];
      y += lineHeight;
      lineCount += 1;
    } else {
      line = testLine;
    }
  }
  ctx.fillText(line, x, y);
  // console.log('lineCount', lineCount);
  return lineCount;
};

const ShareView = ({ logoUrl, picUrl, price, id, title, arg1, arg2, arg3, setShowShareView }: any) => {
  console.log('ShareView_Data', { picUrl, title, arg1, arg2, arg3 });
  const url = window.location.href;
  console.log('url', url);

  const canvasRef = useRef(null);
  const createImage = async () => {
    const ctx = (canvasRef.current as any).getContext('2d');

    //清除画布
    var w = ctx.width;
    var h = ctx.height;
    ctx.clearRect(0, 0, w, h);

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, 320, 500);

    let x = 10;
    let height = 300 + x;

    var img = new Image();
    img.onload = () => {
      ctx.drawImage(img, x, x, 300, 300);
    };
    img.crossOrigin = 'Anonymous';
    // img.crossOrigin = '';
    img.src = picUrl;
    // img.src = await GetImgData(picUrl);

    ctx.fillStyle = 'black';
    height += 20;
    ctx.font = "18px auto";
    height += 20 * fillText2(ctx, title, x, height, 300, 20);
    height += 12;

    ctx.font = "22px auto";
    ctx.fillStyle = 'red';
    height += 22 * fillText2(ctx, price, x, height, 300, 22);
    height += 3;

    ctx.font = "14px auto";
    ctx.fillStyle = 'black';
    height += 14 * fillText2(ctx, '含量|包装：' + arg1, x, height, 300, 14);
    height += 3;

    height += 14 * fillText2(ctx, '产地|公司：' + arg2, x, height, 300, 14);
    height += 3;

    height += 14 * fillText2(ctx, '技术|状态：' + arg3, x, height, 300, 14);
    height += 3;

    height += 10;
    ctx.font = "11px auto";
    ctx.fillStyle = '#8f8f8f';
    height += 10 * fillText2(ctx, '--- From Xirou ---', x, height, 300, 11);

    QRCode.toDataURL(url, { errorCorrectionLevel: 'M', margin: 2 }, function (err, url) {
      if (err) throw err;
      const img = new Image();
      img.onload = function () {
        setTimeout(() => {
          ctx.drawImage(img, 200 + x, 200 + x, 100, 100);
          var logo = new Image();
          logo.onload = () => {
            const _x = 240 + x; const _w = 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(_x - 2, _x - 2, _w + 4, _w + 4);
            ctx.drawImage(logo, _x, _x, _w, _w);
          };
          logo.src = logoUrl;
        }, 100);
      };
      img.src = url;
    });

  };
  // const savePic = () => {
  //   let canvasUrl = (canvasRef.current as any).toDataURL("image/png");
  //   console.log(canvasUrl);
  //   const createEl = document.createElement('a');
  //   createEl.href = canvasUrl;
  //   createEl.download = `${id}#${title} --- From Xirou`; //"download-this-canvas";
  //   createEl.click();
  //   createEl.remove();
  // }
  useEffect(() => {
    createImage();
  }, []);
  return (
    <div className=" bg-black/60 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full  max-h-full">
      <div className="relative p-4">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-4 md:p-5 space-y-4 flex flex-col items-center">
            <button onClick={() => setShowShareView(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
            <canvas
              width='320' // 可设置画布的大小
              height='500'
              ref={canvasRef}
            >
            </canvas>
            <div className='flex space-x-2'>
              {/* <button onClick={savePic} type="button" className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">保存/截屏</button> */}
              {/* <button onClick={() => setShowShareView(false)} type="button" className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded text-sm px-3 py-2 text-xs dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">关闭</button> */}
              <div className='text-xs text-gray-300'>通过截屏/截图分享</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FeiZCProBtnView = ({ hzgm, hfgm, reservation }: any) => {
  let txt = '';
  // hzgm 会诊购买
  if (hzgm) txt = '会诊购买';
  // hfgm 恢复购买
  else if (hfgm) txt = '恢复购买';
  // reservation 预订
  else if (reservation) txt = '预定购买';

  return (
    <button onClick={() => { alert('请联系服务人员进行咨询'); }} type="button"
      className={`w-32 px-5 py-3 text-sm font-medium text-white bg-gradient-to-br rounded-full from-pink-500 to-orange-400 hover:bg-gradient-to-bl  focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 text-center ${false ? 'opacity-65' : ''}`}>
      <span className=''>{txt}</span>
    </button>
  )
}