
import React, { useState, createContext, useContext, useEffect } from "react";

interface INotifyShow {
  message: string,
  autoHideDuration?: number
  type?: 'info' | 'error' | 'success';
}
interface INotifyParams {
  show: (params: INotifyShow) => void;
}

const NotifyContext = createContext<INotifyParams>({ show: (params: INotifyShow) => { } });

const NotifyProvider = (props: any) => {
  let timer: any;
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<INotifyShow | null>(null);
  const show = (params: INotifyShow) => {
    try { clearTimeout(timer); } catch { }

    const defaultParams: INotifyShow = {
      message: '',
      autoHideDuration: 3000,
      type: 'info',
    };
    const _params = { ...defaultParams, ...params };
    // console.log('params', _params)
    setParams(_params)

    if (!open) setOpen(true);
    timer = setTimeout(() => { setOpen(false) }, _params.autoHideDuration);
  }

  // const bgColor = (type?: string) => {
  //   switch (type) {
  //     case "error": return 'bg-red-800';
  //     case "success": return 'bg-green-800';
  //   }
  //   return 'bg-blue-800'
  // }

  const ViewTip = ({ type, message }: any) => {
    return (
      <>
        {type === 'success' &&
          <div id="toast-success" className="max-w-fit z-50 fixed left-0 right-0 top-5 mx-auto flex items-center w-full  p-2 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
              <svg className="w-5 h-5 animate-bounce" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
            </div>
            <div className="ms-3 text-sm font-normal">{message}</div>
            {/* <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button> */}
          </div>
        }
        {type === 'error' &&
          <div id="toast-danger" className="max-w-fit z-50 fixed left-0 right-0 top-5 mx-auto flex items-center w-full  p-2 mb-4 text-gray-500  rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 bg-red-100" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
              <svg className="w-5 h-5 animate-bounce" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
            </div>
            <div className="ms-3 text-sm font-normal">{message}</div>
            {/* <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button> */}
          </div>
        }
        {type === 'info' &&
          <div id="toast-error" className="max-w-fit z-50 fixed left-0 right-0 top-5 mx-auto flex items-center p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-700 dark:text-blue-200">
              <svg className="w-5 h-5 animate-bounce" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
            </div>
            <div className="ms-3 text-sm font-normal">{message}</div>
            {/* <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-warning" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button> */}
          </div>
        }
      </>
    )
  }

  return (
    <NotifyContext.Provider value={{ show }} {...props}>
      {props.children}

      {open ?
        // <div className="{`animate-notify-animate rounded-lg max-w-fit px-2 z-50 fixed py-2 text-white left-0 right-0 top-5 mx-auto ${bgColor(params?.type)}`}>
        //   <div className=""flex justify-center items-center space-x-1">
        //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=""w-6 h-6">
        //       <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        //     </svg>
        //     <span className=""text-2xl">
        //       {params?.message}
        //     </span>
        //   </div>
        // </div>
        <ViewTip type={params?.type} message={params?.message} />
        : null
      }
    </NotifyContext.Provider>
  );
};

const useNotify = () => {
  return useContext(NotifyContext);
};
export { NotifyContext, NotifyProvider, useNotify };