import axios, { AxiosError } from "axios";
import { apiUrl, clear_Spm, clearToken, decodeToken, get_Spm, getToken } from "./global";
import qs from "qs";
import { useNotify } from "./useNotify";
import { useNavigate } from "react-router-dom";

interface IRequestParams {
  url: string,
  jsonData: any,
  fail?: (data: IApiResult) => void
  checkLogin?: boolean
}
export interface IApiResult {
  Code: number,
  Message: string,
  Result: any
}

const _response = (p: Promise<any>, fail: (data: IApiResult) => void) => {
  return p
    .then(({ status, data }: any) => {
      if (status != 200) throw new AxiosError(`status=${status}`);
      // console.log('_response', JSON.stringify(data))
      return data;
    })
    .then(({ Code, Message, Result }: IApiResult) => {
      if (Code == 1) return Result;
      fail({ Code, Message, Result });
      throw new AxiosError(Message, 'custom');
    })
    .catch((err: AxiosError) => {
      // console.log('AxiosError', err);
      const { code, response } = err;
      if (code === 'ERR_NETWORK') {
        // alert('网络错误请稍候重试'); 
        window.location.replace('/error' + '?msg=网络错误请稍候重试');
        return;
      }
      const { status } = (response ?? {}) as any;
      console.error('response catch', code, status)
      if (status == 402) {
        // spm error
        clear_Spm();
        window.location.replace('/password');
        return;
      }
      else if (status == 401) {
        // alert('未登陆或登陆已过期');
        clearToken();
        window.location.replace('/login');
        return;
      }
      else if (status == 403) {
        // alert('无权访问资源');
        fail({ Code: 0, Message: '无权访问资源', Result: null });
      }
      throw new AxiosError(`${code} ${status}`);
    })
}

const defaultFail = ({ Code, Message, Result }: IApiResult): void => {
  alert(Message);
  console.warn(Message);
}

const getHearderParam = (checkLogin: boolean) => {
  const token = getToken();
  const spm = get_Spm() ?? "";
  return checkLogin ? { 'Authorization': token, spm } : { spm };
}

export const Get = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.get(`${apiUrl}${url}?${encodeURI(qs.stringify(jsonData))}`, {
    headers: { ...options }
  }), fail ?? defaultFail)
};

export const PostData = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.post(apiUrl + url, qs.stringify(jsonData), {
    headers: { "Content-Type": "application/x-www-form-urlencoded", ...options }
  }), fail ?? defaultFail)
};

export const PostJson = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.post(apiUrl + url, jsonData, {
    headers: { ...options }
  }), fail ?? defaultFail)
};

// export const GetImgData = (imageUrl: string) => {
//   // // https://forum.bubble.io/t/error-tainted-canvases-may-not-be-exported/318596/4
//   // // Fetch image from URL using Axios
//   // console.log('imageUrl', imageUrl)
//   // const response = await axios.get(imageUrl, {
//   //   responseType: 'arraybuffer' // Ensure response data is treated as binary data
//   // });
//   // // Convert the image buffer to a base64 string
//   // const base64Image = Buffer.from(response.data, 'binary').toString('base64');
//   // // Determine the MIME type of the image (e.g., 'image/jpeg', 'image/png')
//   // const contentType = response.headers['content-type'];
//   // // Construct the data URL
//   // const dataUrl = `data:${contentType};base64,${base64Image}`;
//   // // Return data URL
//   // return dataUrl;

//   // imageUrl = 'https://www.gravatar.com/avatar/a23e8dc1723d63e82db27f5541d60a41?s=64&d=identicon&r=PG&f=y&so-version=2';
//   console.log('imageUrl', imageUrl)
//   axios.get(imageUrl).then(response => {
//     console.log(response.data); // Blank
//     console.log(response.data == null); // False 
//     // console.log(base64.encode(response.data); // Blank
//     const base64Image = Buffer.from(response.data, 'binary').toString('base64');
//     console.log('base64Image', base64Image);
//   }).catch(err => console.log(err));

//   return "";
// }