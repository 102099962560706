import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import AutoScorllTop from "./common/autoTop";
import NoPage from "./NoPage";
import { LoginPage } from "./components/loginPage";

import { HeaderAndFooterView } from "./components/headerAndFooterView";
import { FwsHeaderAndFooterView } from "./components/fws/fwsHeaderAndFooterView";
import { Fws } from "./components/fws";
import { RegPage } from "./components/regPage";
import { ResetPwdPage } from "./components/resetPwdPage";
import { UserHeaderAndFooterView } from "./components/user/userHeaderAndFooterView";
import { User } from "./components/user";
import { List } from "./components/list";
import { PasswordPage } from "./components/passwordPage";
import { Detail } from "./components/detail";
import { Search } from "./components/search";
import { HelpDetail } from "./components/help/helpDetail";
import { ShoppingCar } from "./components/order/shoppingCar";
import ErrorPage from "./ErrorPage";
import { CreateOrder } from "./components/order/createOrder";
import { OrderStatus } from "./components/order/OrderStatus";
import { UserOrder } from "./components/user/order";

export const R = () => {
  return (
    <BrowserRouter>
      <AutoScorllTop>
        <Routes>
          {/* {/* <Route path="/xxx" element={<XXX />} /> */}
          <Route element={<HeaderAndFooterView />}>
            <Route path="/" element={<App />} />

            <Route path="/search" element={<Search />} />
            <Route path="/search/:key" element={<Search />} />
            <Route path="/search/:key/:page" element={<Search />} />

            {/* <Route path="/list" element={<List />} /> */}
            <Route path="/list/:type/:sid" element={<List />} />
            <Route path="/list/:type/:sid/:sid2" element={<List />} />
            <Route path="/list/:type/:sid/:sid2/:page" element={<List />} />
            {/* <Route path="/detail" element={<Detail />} /> */}
            <Route path="/detail/:id" element={<Detail />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/reg" element={<RegPage />} />
            <Route path="/resetpwd" element={<ResetPwdPage />} />

            <Route path="/user" element={<UserHeaderAndFooterView />}>
              <Route path="" element={<User />} />
              <Route path="orders" element={<UserOrder />} />
              <Route path="orders/:page" element={<UserOrder />} />
            </Route>

            <Route path="/fws" element={<FwsHeaderAndFooterView />}>
              <Route path="" element={<Fws />} />
            </Route>

            <Route path="/help/:id" element={<HelpDetail />} />

            <Route path="/shoppingCar" element={<ShoppingCar />} />
            <Route path="/createOrder/:packageKey/:pIds" element={<CreateOrder />} />
            <Route path="/orderStatus/:orderNumber" element={<OrderStatus />} />

          </Route>
          <Route path="/password" element={<PasswordPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </AutoScorllTop>
    </BrowserRouter>
  );
}